import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PantasmaListApi} from './pantasma-api.interface';
import {EventEmitter, Injectable, ViewChild} from '@angular/core';
import {NotificationsComponent} from '../../notifications/notifications.component';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {TokenStorageService} from 'app/_services/token-storage.service';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
const AUTH_API = environment.serverUrl + '/api/auth/';


@Injectable()
export class PantasmaHttpClient {

    @ViewChild(NotificationsComponent) notifications: NotificationsComponent;



    urlBase = environment.serverUrl + '/api/private/';
    public change: EventEmitter<string> = new EventEmitter<string>();
    public changeDetail: EventEmitter<string> = new EventEmitter<string>();
    public changeRelationDetail: EventEmitter<string> = new EventEmitter<string>();
    public comboLoaded: EventEmitter<string> = new EventEmitter<string>();
    public otherChange: EventEmitter<string> = new EventEmitter<string>();

    constructor(private _httpClient: HttpClient, private tokenStorageService:TokenStorageService) {}

    refreshToken(token: string): Observable<any> {
        return this._httpClient.post(AUTH_API + 'refresh-token', {
          refreshToken: token
        }, httpOptions);
      }

    getList(sort: string, order: string, page: any, requestMapping: string, pageSize: any, filters: any, filterFields: any, filtersFrom: any, filtersTo: any, idById?: any, parentMapping?: any): Observable<PantasmaListApi> {

        var self = this;
        var filter = this.getFilters(filters,filterFields);
        var dates = this.getDates(filtersFrom, filtersTo, filterFields);
        var contains = this.getContains(filters, filterFields);

        if(idById!=null){
            filter[parentMapping + "Id"] = idById;
        }

        var query = {
            item: filter,
            dates: dates,
            contains:contains
        };

        const href = this.urlBase + requestMapping + '/find';
        const requestUrl =
            `${href}?order=${sort}&direction=${order}&page=${page}&size=${pageSize}`;

            setTimeout(function(){

                self.refreshToken(self.tokenStorageService.getRefreshToken()).subscribe(next => {
                    self.tokenStorageService.saveToken(next.accessToken);
                }, err => {

                });

            },2);
        // const headers = new HttpHeaders({'Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods':'GET,POST,OPTIONS,DELETE,PUT'});
        return this._httpClient.post<PantasmaListApi>(requestUrl, query, httpOptions);
    }


    getDates(filtersFrom: string[], filtersTo: string[], filterFields: string[]):any{

        var list = [];

        filterFields.forEach((element,index) =>{

          var json = {};
          if(filtersFrom[index]!='' && filtersFrom[index] != null){


              json = {
                    from: filtersFrom[index],
                    field: element
              };

            list.push(json);
          }

          if(filtersTo[index]!='' && filtersTo[index] != null){


            json = {
                  to: filtersTo[index],
                  field: element
            };

             list.push(json);
            }

        });

        return list;

    }

    getContains(filters: string[], filterFields: string[]): any {

        var list = [];

        filterFields.forEach((element, index) => {
            if (filters[index] != '' && element.endsWith('Contains')) {
                var json = {};

                //split filters[index] by comma an add it to a array
                var values = filters[index];

                json = {
                    value: values,
                    field: element.replace("Contains", "Id")
                };

                list.push(json);

            }
        });

        return list;

    }

    getFilters(filters:string[],filterFields: string[]):any{

        var json = {};

        filterFields.forEach((element,index) =>{
          if(filters[index]!=''){
            if(element.indexOf('.')!= -1){
              if(json[element.split(".")[0]] == undefined){
                json[element.split(".")[0]] ={};
              }
              json[element.split(".")[0]][element.split(".")[1]] = filters[index];
            }
            else{
              json[element] = filters[index];
            }

          }
        });

        return json;

    }

    deleteItem(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id;
        return this._httpClient.delete(requestUrl).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    getItem(id: number, requestMapping: string) {
        const requestUrl = this.urlBase + requestMapping + '/' + id;
        return this._httpClient.get(requestUrl)
    }

    createItem(item, requestMapping: string, main: boolean, other: boolean = false){


        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/';
        return this._httpClient.post(requestUrl, item, httpOptions).pipe(
            map(result => {

                if(!other){
                    if(main){
                        self.changeDetail.emit(result['id']);
                    }
                    else{
                        self.changeRelationDetail.emit(result['id']);
                    }
                }
                else{
                    return result;
                }

            })
        );

    }

    updateItem(item, requestMapping: string, main: boolean){
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + item['id'] + '/';
        return this._httpClient.put(requestUrl, item, httpOptions).pipe(
            map(_ => {
                if(main){
                    self.changeDetail.emit(item['id']);
                }
                else{
                    self.changeRelationDetail.emit(item['id']);
                }

            })
        );
    }

    updateJSON(item, requestMapping: string, main: boolean){
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/updateJSON/' + item['id'] + '/';
        return this._httpClient.put(requestUrl, item.properties, httpOptions).pipe(
            map(result => {
               return result;
            })
        );
    }

    getMetadata(requestMapping: string) {
        const requestUrl = this.urlBase + requestMapping + '/metadata/';
        return this._httpClient.get(requestUrl);
    }

    export(format: String, requestMapping: string, columns: string[], type: string, ids: number[],  filters: any, filterFields: any, filtersFrom: any, filtersTo: any, idById?: any, parentMapping?: any, userCode?: String){
        const requestUrl = this.urlBase + requestMapping + '/export/';

        var filter = this.getFilters(filters,filterFields);
        var dates = this.getDates(filtersFrom, filtersTo, filterFields);

        if(idById!=null){
            filter[parentMapping + "Id"] = idById;
        }

        var json ={
            extension:format,
            filter:filter,
            fields: columns,
            ids: ids,
            dates:dates,
            code: userCode
        }

        return this._httpClient.post(requestUrl,json,{ responseType: 'blob' });

    }

    activate(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/activate';
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    changeState(state: string, id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/changeState/' + id + '/' + state;
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                    return result;
                })
            );
    }

    changeStateFormulario(state: string, id: number, formulario: string, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/changeStateFormulario/' + id + '/' + formulario + '/' + state;
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                        self.changeDetail.emit(id+"");
                })
            );
    }

    citar(id: number, requestMapping: string, date: Date, hora:string) {
        var self=this;
        if(hora==undefined || hora == null || hora ==''){
            hora="vacio";
        }
        const requestUrl = this.urlBase + requestMapping + '/citar/' + id + '/' + date + '/' + hora;
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                    return result;
                })
            );
    }

    changeStateMultiple(state: string,  ids: number[], requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/changeState/' + state;
        var json ={
            ids: ids
        }
        return this._httpClient.put(requestUrl,json).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    deactivate(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/deactivate';
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    clone(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/clone';
        return this._httpClient.put(requestUrl,{}).pipe(
                // catchError(err => {
                //     if ([401,422,  403].indexOf(err.status) !== -1) {
                //         // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                //         this.tokenStorageService.signOut();
                //     }

                //     const error = err.error.message || err.statusText;
                //     return throwError(error);
                // }),
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    getCombo(requestMapping: string): Observable<any>{

        var comboUrlLoad = requestMapping;

        if(requestMapping.startsWith("mantenimiento")){
            comboUrlLoad = "mantenimiento";
            const requestUrl = this.urlBase + comboUrlLoad + '/comboByTipo/' + requestMapping.replace("mantenimiento","");
            return this._httpClient.get(requestUrl).pipe(
                map(data => {
                    this.comboLoaded.emit(requestMapping);
                    return data;

                })
            );
        }
        else{
            const requestUrl = this.urlBase + comboUrlLoad + '/combo';
            return this._httpClient.get(requestUrl).pipe(
            map(data => {
                this.comboLoaded.emit(requestMapping);
                return data;

            })
        );
        }


    }

    descargaQueryEstadistica(requestMapping: string, id: any) {
        const requestUrl = this.urlBase + requestMapping + '/descargaQueryEstadistica/' + id;
        return this._httpClient.post(requestUrl, {}, {responseType: 'blob'});
    }

    generarInforme(requestMapping: string, tipoReport: string, idReport: number,  id:  any){
        const requestUrl = this.urlBase + requestMapping + '/generarInforme/' + id + '/' + idReport + '/' + tipoReport;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    descargarSuenlace(requestMapping: string, id:  any){
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/descargarSuenlace/' ;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    descargaFollaCarga(requestMapping: string, id:  any){
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/descargarFollaCarga/' ;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    imprimir(requestMapping: string, id:  any) {
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/descargar/';
        return this._httpClient.post(requestUrl, {}, {responseType: 'blob'});
    }

    descargaAlbaran(requestMapping: string, id:  any){
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/descargaAlbaran/' ;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    generarFactura(requestMapping: string, id:  any){
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/generarFactura' ;
        return this._httpClient.post(requestUrl,{},{});
    }

    imprimirFactura(requestMapping: string, id:  any){
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/imprimirFactura' ;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    pagarFactura(requestMapping: string, id: number){
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/pagarFactura' ;
        return this._httpClient.post(requestUrl,{},{}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }

    rectificarFactura(requestMapping: string, id: number){
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/rectificarFactura' ;
        return this._httpClient.post(requestUrl,{},{}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }

    marcarPredeterminado(requestMapping: string, id: number){
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/marcarPredeterminado' ;
        return this._httpClient.get(requestUrl,{}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }

    download(requestMapping: string, tipoDoc: string,  id:  number){
        const requestUrl = this.urlBase + requestMapping + '/download/' + id + '/' + tipoDoc;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });

    }

    generarQr(requestMapping: string, idPersona: string){
        const requestUrl = this.urlBase + requestMapping  + '/' + idPersona + '/generarQr';
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    upgrade(requestMapping: string, id: number) {
        const requestUrl = this.urlBase + requestMapping  + '/' + id + '/upgrade';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }

    downgrade(requestMapping: string, id: number) {
        const requestUrl = this.urlBase + requestMapping  + '/' + id + '/downgrade';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }

    cerrar(requestMapping: string, id: number) {
        const requestUrl = this.urlBase + requestMapping  + '/' + id + '/cerrar';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }


    import(id: number, requestMapping: string, tipo: number, file: FormData, massive: boolean){
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/import' + (massive?'Massive':'') + '/' + id + "/" + tipo;
        return this._httpClient.post(requestUrl, file, {});

    }

    importarVentas(file: FormData, idCliente: number) {
        const self = this;
        const requestUrl = this.urlBase + 'venta/importarVentas/' + idCliente;
        return this._httpClient.post(requestUrl, file, {}).pipe(
            map(result => {
                self.change.emit('change');
                self.changeDetail.emit('change');
            })
        );
    }

    descargarExcelCompleto(requestMapping: string, tipo: string) {
        const requestUrl = this.urlBase + requestMapping + '/descargarExcel';
        return this._httpClient.post(requestUrl, {}, {responseType: 'blob'});
    }

    registerStart(){
        const requestUrl = AUTH_API + 'register-biometric-start';
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    registerFinish(credentials){
        const requestUrl = AUTH_API + 'register-biometric-finish';
        return this._httpClient.post(requestUrl,credentials).pipe();
    }

    deletePacs(id: number, idTipo: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/deletePacs?id=' + id + "&tipo="+idTipo;
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    downloadPacs(requestMapping: string, id:  number, idTipo: number){
        const requestUrl = this.urlBase + requestMapping + '/downloadPacs/' + id + '/'+idTipo;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });

    }

    getListFiles(requestMapping: string, id:  number, idTipo: number) {
        const requestUrl = this.urlBase + requestMapping + '/listFiles/' + id + '/'+idTipo;
        return this._httpClient.get(requestUrl);
    }


    anonymize(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping  + '/' + id + '/anonymize/';
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    rejectAnatomicalCase(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/rejectAnatomical';
        return this._httpClient.put(requestUrl,{}).pipe(
            map(result => {
                self.change.emit("change");
            })
        );
    }

    guardarTareaPersona(calendario, requestMapping: string) {
        const requestUrl = this.urlBase + requestMapping + '/guardarTareaPersona';
        return this._httpClient.post(requestUrl, calendario, {});
    }

    obtenerArticulos(clienteId: number) {
        const requestUrl = this.urlBase + 'articulo/' + clienteId + '/obtenerArticulos';
        return this._httpClient.get(requestUrl);
    }

    obtenerClientes(clienteId: number) {
        const requestUrl = this.urlBase + 'clienteventa/' + clienteId + '/obtenerClientes';
        return this._httpClient.get(requestUrl);
    }

    pagar(pago: any) {
        const requestUrl = this.urlBase + 'ventaCliente/pagar';
        return this._httpClient.post(requestUrl, pago, {});
    }

    realizarDevolucion(devolucion: any) {
        const requestUrl = this.urlBase + 'vale/devolucion';
        return this._httpClient.post(requestUrl, devolucion, {});
    }

    obtenerEspeciales(clienteId: number) {
        const requestUrl = this.urlBase + 'cliente/' + clienteId + '/obtenerEspeciales';
        return this._httpClient.get(requestUrl);
    }

    obtenerTiposPago(clienteId: number) {
        const requestUrl = this.urlBase + 'tipoPago/' + clienteId + '/obtenerTiposPago';
        return this._httpClient.get(requestUrl);
    }

    obtenerCierre(clienteId: number, fecha: any) {
        const requestUrl = this.urlBase + 'ventaCliente/' + clienteId + '/obtenerCierre';
        return this._httpClient.post(requestUrl, fecha, {});
    }

    obtenerEntidadesBancarias(clienteId: number) {
        const requestUrl = this.urlBase + 'entidad/' + clienteId + '/obtenerEntidadesBancarias';
        return this._httpClient.get(requestUrl);
    }

    obtenerVentas(clienteId: number) {
        const requestUrl = this.urlBase + 'ventaCliente/' + clienteId + '/obtenerVentasDevolucion';
        return this._httpClient.get(requestUrl);
    }

    obtenerCliente(clienteId: number) {
        const requestUrl = this.urlBase + 'cliente/' + clienteId + '/obtenerCliente';
        return this._httpClient.get(requestUrl);
    }

    imprimirTicket(message: string) {
        const requestUrl =  'http://localhost:8081/api/public/imprimir';
        return this._httpClient.post(requestUrl, message, {});
    }

    pagarVenta(requestMapping: string, id: number){
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/pagarVenta' ;
        return this._httpClient.post(requestUrl,{}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }

    imprimirImpresoraTermica(text: string, clienteId: number) {
        const requestUrl = environment.moduloLocal + '/api/public/imprimir/' + clienteId;
        return this._httpClient.post(requestUrl, text, {});
    }

    imprimirTicketVenta(id: number) {
        var self = this;
        if(environment.impresionLocalActiva){
            const requestUrl = this.urlBase + 'ventaCliente/' + id + '/imprimirTicketVenta/false';
            return this._httpClient.get(requestUrl).subscribe( next=>{
                self.imprimirImpresoraTermica(next['texto'], next['clienteId']);
            });
        }
        else{
            const requestUrl = this.urlBase + 'ventaCliente/' + id + '/imprimirTicketVenta/true';
            return this._httpClient.get(requestUrl).subscribe();
        }
        return null;
    }

    imprimirTicketVentaYTarjetas(venta: any) {
        var self = this;
        if(environment.impresionLocalActiva){
            const requestUrl = this.urlBase + 'ventaCliente/' + venta.ventaClienteId + '/imprimirTicketVenta/false';
            return this._httpClient.get(requestUrl).subscribe( next=>{
                self.imprimirImpresoraTermica(next['texto'], next['clienteId']).subscribe( data => {
                    if(venta.tarjetasFidelizacion.length > 0) {
                        venta.tarjetasFidelizacion.forEach(e => {
                            self.imprimirDocumento('tarjetaFidelizacion', e);
                        });
                    }
                    if(venta.ticketsRegalo.length > 0) {
                        venta.ticketsRegalo.forEach(e => {
                            self.imprimirDocumento('ticketRegalo', e);
                        });
                    }
                    if(venta.vales.length > 0) {
                        venta.vales.forEach(e => {
                            self.imprimirDocumento('vale', e);
                        });
                    }
                });
            });
        }
        else{
            const requestUrl = this.urlBase + 'ventaCliente/' + venta.ventaClienteId + '/imprimirTicketVenta/true';
            return this._httpClient.get(requestUrl).subscribe(data => {
                if(venta.tarjetasFidelizacion.length > 0) {
                    venta.tarjetasFidelizacion.forEach(e => {
                        self.imprimirDocumento('tarjetaFidelizacion', e);
                    });
                }
                if(venta.ticketsRegalo.length > 0) {
                    venta.ticketsRegalo.forEach(e => {
                        self.imprimirDocumento('ticketRegalo', e);
                    });
                }
                if(venta.vales.length > 0) {
                    venta.vales.forEach(e => {
                        self.imprimirDocumento('vale', e);
                    });
                }
            });
        }
    }

    imprimirDocumento(requestMapping: string, id: number) {
        var self = this;
        if(environment.impresionLocalActiva){
            const requestUrl = this.urlBase + requestMapping + '/' + id + '/imprimir/false';
            return this._httpClient.get(requestUrl).subscribe( next=>{
                self.imprimirImpresoraTermica(next['texto'], next['clienteId']);
            });
        }
        else{
            const requestUrl = this.urlBase + requestMapping + '/'  + id + '/imprimir/true';
            return this._httpClient.get(requestUrl).subscribe();
        }
    }

    imprimirCierre(clienteId: number, fecha: any) {

        var self = this;
        if(environment.impresionLocalActiva){
            const requestUrl = this.urlBase + 'ventaCliente/' + clienteId + '/imprimirCierre/false';
            return this._httpClient.post(requestUrl, fecha, {}).subscribe( next=>{
                self.imprimirImpresoraTermica(next['texto'], next['clienteId']);
            });
        }
        else{
            const requestUrl = this.urlBase + 'ventaCliente/' + clienteId + '/imprimirCierre/true';
            return this._httpClient.post(requestUrl, fecha, {}).subscribe( next => {});
        }
    }

    lock(id: number, requestMapping: string) {
        const self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/lock';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit('change');
            })
        );
    }

    sign(id: number, requestMapping: string) {
        const self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/sign';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit('change');
            })
        );
    }

    downloadSigned(id: number, requestMapping: string){
        const requestUrl = this.urlBase  + requestMapping +  '/downloadSigned/' + id + '/';
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });

    }

    unlock(id: number, requestMapping: string) {
        const self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/unlock';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit('change');
            })
        );
    }

    obtenerTiposTramite() {
        const requestUrl = this.urlBase + 'tipotramite/tiposTramite';
        return this._httpClient.get(requestUrl);
    }

    marcarComoLeida(id: number) {
        const self = this;
        const requestUrl = this.urlBase + 'notificacion' + '/' + id + '/marcarComoLeida';
        return this._httpClient.put(requestUrl, {});
    }
}
