import {Injectable} from '@angular/core';
import { Role } from 'app/_models/role';

export interface RouteInfo {
    path: string;
    title: string;
    icon?: string;
    class: string;
    authenticate?: Boolean;
    roles?: Role[];
    separatorAfter?: boolean,
    submenus?: RouteInfo[],
    modulos?: string[]
}

@Injectable({
    providedIn: 'root'
})
export class RoutesService {

    constructor() {
    }

    getRoutes(){

        var routes = [

            // { path: '/nuevoTramite', title: 'Dashboard',  icon: 'edit_document', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['tramikar'], separatorAfter:true},
            {path: '/vehiculo', title: 'Stock vehículos', icon: 'directions_car', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['tramikar'], separatorAfter:true},
            {path: '/proveedor', title: 'Proveedores', icon: 'business_center', class: '', authenticate: true, roles: [Role.User], modulos: ['tramikar'], separatorAfter:true},
            // {path: '/expediente', title: 'Expedientes', icon: 'description', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['tramikar']},
            {
                path: '', title: 'Administración', icon: 'admin_panel_settings', class: '', authenticate: true, roles: [Role.Admin], modulos: ['tramikar'], separatorAfter:true,
                submenus: [
                    // {path: '/tipotramite', title: 'Tipos de trámites', icon: 'group', class: '', authenticate: true, roles: [Role.Admin], modulos: ['tramikar']},
                    {path: '/tipologia', title: 'Tipologías documentales', icon: 'pageview', class: '', authenticate: true, roles: [Role.Admin], modulos: ['tramikar']},
                    {path: '/serviciodgt', title: 'Servicios DGT', icon: 'pageview', class: '', authenticate: true, roles: [Role.Admin], modulos: ['tramikar']},
                    // {path: '/gestion', title: 'Gestiones', icon: 'admin_panel_settings', class: '', authenticate: true, roles: [Role.Admin], modulos: ['tramikar']},
                    // {path: '/persona', title: 'Personas', icon: 'group', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['tramikar']},
                ]
            },
            {
                path: '', title: 'Usuarios', icon: 'group', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['tramikar'],
                submenus: [
                    {path: '/user', title: 'Usuario', icon: 'group', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['tramikar']},
                    {path: '/cliente', title: 'Clientes', icon: 'support_agent', class: '', authenticate: true, roles: [Role.Admin], modulos: ['tramikar']},
                    {path: '/notificacion', title: 'Notificaciones', icon: 'support_agent', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['tramikar']},
                    // {path: '/aviso', title: 'Avisos', icon: 'campaign', class: '', authenticate: true, roles: [Role.Admin], modulos: ['tramikar']},
                ]
            }


        ];

        return routes;
    }

}
