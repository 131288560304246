import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TokenStorageService} from '../../_services/token-storage.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import { RouteInfo, RoutesService } from 'app/_services/routes.service';

declare const $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    environment = environment;
    user: any;
    currentRout: any;
    routes:RouteInfo[] = [];
    loadUser:boolean = false;

    constructor(private tokenStorageService: TokenStorageService, private domSanitizer: DomSanitizer, private router: ActivatedRoute, private routesService: RoutesService) {
        this.routes = routesService.getRoutes();
        this.menuItems = this.routes;
    }

    ngOnInit() {
        if(!this.loadUser){
            this.user=this.tokenStorageService.getUser();
        }
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };


    logout(): void {
        this.tokenStorageService.signOut();
        window.location.reload();
    }

    authenticationCheckBoolean(): boolean {
        return this.tokenStorageService.getToken() != null;
    }

    authenticationCheck(r: RouteInfo): boolean {

        if (r.authenticate != null) {
            if (r.authenticate) {


                if (this.tokenStorageService.getToken() != null) {
                    if (r.roles != null) {

                        let counter = 0;
                        for (var i = 0; i < r.roles.length; i++) {
                            if (this.tokenStorageService.getUser().roles.includes(r.roles[i])) {
                                counter++;
                            }
                        }

                        return counter > 0;


                    } else {
                        return true;
                    }
                } else {
                    return false;

                }


            } else {
                return this.tokenStorageService.getToken() == null;
            }

        } else {
            return true;
        }


    }

    isActivatedModule(menuItem) {

        return environment.modulos == undefined || menuItem.modulos == undefined || menuItem.modulos.some(r => environment.modulos.includes(r))

    }

}
