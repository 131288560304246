import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

const AUTH_API = environment.serverUrl + '/api/auth/';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient) {
    }

    login(credentials): Observable<any> {
        return this.http.post(AUTH_API + 'signin', {
            username: credentials.username,
            password: credentials.password
        }, httpOptions);
    }

    verify(credentials, password): Observable<any> {
        return this.http.post(AUTH_API + 'verify', {
            username: credentials.username,
            password: password,
            code: credentials.code
        }, httpOptions);
    }

    refreshToken(token: string): Observable<any> {
        return this.http.post(AUTH_API + 'refresh-token', {
          refreshToken: token
        }, httpOptions);
      }

    register(user): Observable<any> {
        return this.http.post(AUTH_API + 'signup', {
            username: user.username,
            email: user.email,
            password: user.password
        }, httpOptions);
    }

    forgotPassword(username: String): Observable<any> {

        return this.http.post(AUTH_API + 'forgot-password/', {
            username: username,
        }, httpOptions);

    }

    resetPasswordToken(username: String): Observable<any> {

        return this.http.get(AUTH_API + 'reset-password-token/?username=' + username, {},).pipe();

    }

    resetPassword(token: String, password: String): Observable<any> {

        return this.http.post(AUTH_API + 'reset-password/', {
            token: token,
            password: password
        }, httpOptions);

    }

    checkToken(token: String): Observable<any> {

        return this.http.post(AUTH_API + 'check-token/' + token, {},httpOptions);

    }

}
